import React from "react";
import TotalSales from "./TotalSales";
import TransFre from "./TransFreq";
import SalesTrend from "./SalesTrend";
import SalesChart from "./SalesChart";
import RealtimeStatus from "./RealtimeStatus";
import MachinePerTable from "./MachinePerTable";
import ProductsPerTable from "./PorductsPerTable";

import { Tab, Tabs } from "react-bootstrap";
import { FaDownload } from "react-icons/fa6";
import DateRangeDropdown from "./DateRangeDropdown";

import "../components/css/MachinePerformance.css";


const MachinePerformance = () => {


    return(
        <>
            <div className="container">
                <div className="machineArea"> 
                    <div className="area"> {/* 60% */}
                        <div className="machineTitle">
                            <div>
                                <h4>Machines Performance</h4>
                            </div>
                            <div>
                                <div style={{ display:"flex", alignItems:"center"}}>
                                    <DateRangeDropdown />
                                    <FaDownload style={{ paddingLeft:"1%", width:"30px" }} />
                                </div>
                            </div>
                        </div>
                        <div className="handleSaleTrans">
                            <TotalSales />
                            <TransFre />
                        </div>
                        <div className="handleSaleCharts">
                            <Tabs defaultActiveKey="salesTrend" id="sales-tabs" className="mb-3">
                                <Tab eventKey="salesTrend" title="Sales Trend">
                                    <SalesChart />
                                </Tab>
                                <Tab eventKey="salesChart" title="Sales Chart">
                                    <SalesTrend />
                                </Tab>
                            </Tabs>
                        </div>
                        <div className="handleSaleCharts">
                            <MachinePerTable />
                            <ProductsPerTable />
                        </div>
                    </div>
                    <div className="realtimeDiv">
                        <RealtimeStatus />
                    </div>
                </div>
            </div>
        </>
    )
};

export default MachinePerformance;